import React, { useState } from 'react'
import './applayout.css'
import Sidebar from '../sidebar/Sidebar';
import Apcontent from '../appcontent/Apcontent';
import Header from '../topbar/Header';

const Applayout = () => {
  const [show, setShow] = useState(false)

  return (
    <>
<main className={show ? 'space-toggle' : null}>
    <header className={`header ${show ? 'space-toggle' : null}`}>
    <div className='header-toggle' onClick={() => setShow(!show)}>
    <i className="fas fa-bars"></i>
    </div> 
        <Header/>
    </header>
        <div>
        <aside className={`sidebar ${show ? 'show' : null}`}>
        <nav className='nav'>
        <Sidebar/>
        </nav>
        </aside>
        <Apcontent/>

        </div>     
        

    </main>
    </>

    
  )
}

export default Applayout

import React, { useState } from 'react'
import '../login/login.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HashLoader } from 'react-spinners'
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../api/Axios';



const schema = yup.object({
  email: yup.string().required("Enter your email").email(),
  username: yup.string().required("Enter your email").min(5,"username must be 5 or more characters"),
  password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character').matches(/^(?=.*[0-9])/, 'Password must contain at least one number'),
  password_confirmation: yup.string().label('confirm password').required().oneOf([yup.ref('password'), null], 'Passwords must match').min(8, "Password must be at least 8 characters"),

});

const Signup = () => {
  
  const signupurl = "/admin/admin-signup"
     const [loading , setloading] = useState(false)
    const navigate = useNavigate("")

    const {register, handleSubmit,formState: { errors }} = useForm({ 
        resolver: yupResolver(schema),
        email:'',
        username:'',
        password:'' ,
        password_confirmation:''
    });
    toast.error(errors.message,{
        position:toast.POSITION.TOP_CENTER
    })

    const formSubmit = async ({email, username, password, password_confirmation})=>{
        const res = await axios.post(signupurl, {email,username, password, password_confirmation})
        .then(response=>{
            //console.log(response.data);
            const {data, access_token, message} = response.data
            if(access_token){

                    localStorage.setItem('knmpadmin', JSON.stringify(response.data));
                    toast.success(message,{
                        position:toast.POSITION.TOP_CENTER
                    })
                    setloading()
                    setTimeout(()=>{
                        navigate("/adminlogin")
                    },2000)
                }
                if(!access_token){
                  toast.error(data.data.email[0],{
                      position:toast.POSITION.TOP_CENTER
                  }) 
              }
        }).catch(error=>{
            //console.log(error);
            toast.error(error.message,{
                position:toast.POSITION.TOP_CENTER
            }) 
        })
        return res
    }
  return (
    <>
     {
      loading?    
      <span>Loading...<HashLoader className='clips' color="#44045d" loading={loading} size={50}  /></span> 
      :
       <div id="login-page">
       <ToastContainer/>
 <div className="login">
   <h2 className="login-title">KNMP Signup</h2>
   <p className="notice">Please Signup to access the system</p>
   <form className="form-login" onSubmit={handleSubmit(formSubmit)}>
    <label htmlFor="email">Enter email</label>
     <div className="input-email">
       <i className="fas fa-envelope icon"></i>
       <input type="email" name="email" placeholder="Enter your email" {...register('email', { required: true })} />
     </div>
     <p className='danger'>
          {errors.email?.message}
      </p>
     
     <label htmlFor="email">Username</label>
     <div className="input-email">
       <i className="fas fa-envelope icon"></i>
       <input type="text" name="username" placeholder="Enter your username" {...register('username', { required: true })} />
     </div>
     <p className='danger'>
          {errors.email?.message}
      </p>

    <label htmlFor="password">Password</label>
     <div className="input-password">
       <i className="fas fa-lock icon"></i>
       <input type="password" name="password" placeholder="Enter your password" {...register('password', { required: true })}  />  
     </div>
     <p className='danger'>
          {errors.password?.message}
      </p>
    
    <label htmlFor="password">Confirm Password</label>
     <div className="input-password">
       <i className="fas fa-lock icon"></i>
       <input type="password" name="password" placeholder="Enter your confirm password" {...register('password_confirmation', { required: true })}  />  
     </div>
     <p className='danger'>
          {errors.password_confirmation?.message}
      </p>
    
     <div className="checkbox">
       {/* <label htmlFor="remember">
         <input type="checkbox" name="remember" />
         Remember me
       </label> */}
     </div>
     <button className='btnadlogin' type="submit"><i className="fas fa-user"></i>Register to login</button>
   </form>
   <Link to='/adminlogin'>Login here</Link>
   <div className="created">
     <p>developed by <a href="#/">Delax enterprise solutions</a></p>
   </div>
 </div>
 <div className="background">
   <h1>Action without thoughts is empty. Thoughts without action is blind.</h1>
 <br />
 </div>
</div>
     }
</>
  )
}

export default Signup
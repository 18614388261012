import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import './header.css'


const Header = () => {
  const navigate = useNavigate("")


  const logout = () => {
    toast.success("Successfully Logout!",{
      position:toast.POSITION.TOP_CENTER
    })
    setTimeout(()=>{
      localStorage.removeItem('knmpdata');
      localStorage.clear()
      navigate("/")
    }, 3000)
  }

  return (
    <>
      <ToastContainer/>
      <Link to="/reschedule-ticket" className='header-link'>
        <i class="fa-brands fa-gg-circle"></i>
        <span className='nav-link-name'>Reschedule Ticket</span>
        <span className='nav-tooltip'>Reschedule Ticket</span>
    </Link>
      <Link to="/ticket-status" className='header-link'>
          <i class="fa-brands fa-usps"></i>
           <span className='nav-link-name'>Ticket Status</span>
        <span className='nav-tooltip'>Ticket Status</span>
    </Link>
    <Link to="/profile" className='header-link'>
          <i className="fas fa-gear"></i>
         <span className='nav-link-name'>Settings</span>
        <span className='nav-tooltip'>Settings</span>
    </Link>
       <Link to={logout} onClick={logout} className='header-link'>
       <i className=" fas fa-light fa-user"></i>
       <span className='nav-tooltip'>logout</span>

      </Link>
     


    </>
  )
}

export default Header
export const data = [
    {
        "id": 1,
        "email":"max.boakye@des.com",
        "names":"max baoakye",
        "phonenumber":"0245759890",
        "ticketno":"knmp2949023",
        "noofticket":2,
    },
    {
        "id": 2,
        "email":"delaqui@gmail.com",
        "names":"mena mina",
        "phonenumber":"0245759890",
        "ticketno":"knmp2020123",
        "noofticket":6,
    },
    {
        "id": 3,
        "email":"manucho",
        "names":"mena mina",
        "phonenumber":"0245759890",
        "ticketno":"knmp2020123",
        "noofticket":6,
    },
    {
        "id": 4,
        "email":"manucho",
        "names":"mena mina",
        "phonenumber":"0245759890",
        "ticketno":"knmp2020123",
        "noofticket":6,
    },
    {
        "id": 5,
        "email":"manucho@gmail.com",
        "names":"mena meghan",
        "phonenumber":"+2238459493",
        "ticketno":"knmp909823",
        "noofticket":1,
    },

]
import React, { useEffect, useState } from 'react'
import './login.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HashLoader } from 'react-spinners'
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../api/Axios';



const schema = yup.object({
  username: yup.string().required("Enter your email").min(5,"username must be 5 or more characters"),
  password:yup.string().required("Password is required!").min(8, "Password must be more than 8 characters!").matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character').matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character').matches(/^(?=.*[0-9])/, 'Password must contain at least one number'),
});

const Login = () => {
  const signupurl = "/admin/admin-login"
     const [loading , setloading] = useState(false)
    const navigate = useNavigate("")

    const {register, handleSubmit,formState: { errors }} = useForm({ 
        resolver: yupResolver(schema),
        username:'',
        password:'' ,
    });
    toast.error(errors.message,{
        position:toast.POSITION.TOP_CENTER
    })

useEffect(()=>{
 setloading(true);
 setTimeout(()=>{
    setloading(false);
 }, 3000)
},[])

// const checkor = ()=>{
//   setloading(true);
//  setTimeout(()=>{
//     setloading(false);
//     navigate("/checkor")
//  }, 2000)
// }

    const formSubmit = async ({username, password})=>{
        const res = await axios.post(signupurl, {username, password})
        .then(response=>{
            //console.log(response.data);
            const {data, access_token, message} = response.data
            if(access_token){
                    localStorage.setItem('knmpadmin', JSON.stringify(response.data));
                    toast.success(message,{
                        position:toast.POSITION.TOP_CENTER
                    })
                    setloading()
                    setTimeout(()=>{
                        navigate("/dashboard")
                    },2000)
                }
                if(!access_token){
                    toast.error(data.data.username,{
                        position:toast.POSITION.TOP_CENTER
                    }) 
                }
        }).catch(error=>{
            //console.log(error);
            toast.error(error.message,{
                position:toast.POSITION.TOP_CENTER
            }) 
        })
        return res
    }
  return (
    <>
     {
      loading?    
      <span className='loader'><HashLoader className='clips' color="#008552" loading={loading} size={80}  />KNMP loading...</span> 
      :
       <div id="login-page">
       <ToastContainer/>
 <div className="login">
   <h2 className="login-title">KNMP Login</h2>
   <p className="notice">Please login to access the system</p>
   <form className="form-login" onSubmit={handleSubmit(formSubmit)}>
     <label htmlFor="email">Username</label>
     <div className="input-email">
       <i className="fas fa-envelope icon"></i>
       <input type="text" name="username" placeholder="Enter your username" {...register('username', { required: true })} />
     </div>
     <p className='danger'>
              {errors.username?.message}
         </p>
     <label htmlFor="password">Password</label>
     <div className="input-password">
       <i className="fas fa-lock icon"></i>
       <input type="password" name="password" placeholder="Enter your password" {...register('password', { required: true })}  />  
     </div>
     <p className='danger'>
          {errors.password?.message}
      </p>
    
     <button className='btnadlogin' type="submit"><i className="fas fa-door-open"></i>Login</button>
   </form>
   {/* <Link to='/adminsignup'>Create an account here</Link> */}
   <Link to='/checkor' >I am a Checkor? </Link>

     <Link href="#/">Forgot your password?</Link>
   <div className="created">
     <p>developed by <Link>Delax enterprise solutions</Link></p>
   </div>
 </div>
 <div className="background">
   <h1>Action without thoughts is empty. Thoughts without action is blind.</h1>
 <br />
 </div>
</div>
     }
</>
  )
}

export default Login
import React, { useEffect, useState } from 'react'
import './widgetslg.css'
import user from '../../assets/user.png'
import axios from '../../api/Axios'


const WidgetsLg = () => {
  const transactapiurl = "/admin/admin-get-transaction"


  const[trnsct, settrnsct] = useState([])
  useEffect(()=>{
    const transact = async()=>{
      await axios.post(transactapiurl).then(response=>{
        settrnsct(response.data.data.message[0])
      })
    }
    transact();
  },[])

    const Button = ({ type }) => {
        return <button className={"widgetLgButton " + type}>{type}</button>;
      };
      return (
        <div className="widgetLg">
          <h3 className="widgetLgTitle">Latest transactions</h3>
          <table className="widgetLgTable">
            <tr className="widgetLgTr">
              <th className="widgetLgTh">Customer</th>
              <th className="widgetLgTh">#. of ticket</th>
              <th className='widgetLgTh'>Ticket No</th>
              <th className="widgetLgTh">Reservation Date</th>
              <th className="widgetLgTh">Amount</th>
              <th className="widgetLgTh">Status</th>
              <th className="widgetLgTh">Action</th>

            </tr>
            <tr className="widgetLgTr">
              <td className="widgetLgUser">
                <img
                  src={user}
                  alt=""
                  className="widgetLgImg"
                />
                <span className="widgetLgName">{trnsct.fullname}</span>
              </td>
              <td className="widgetLgDate">{trnsct.number_of_ticket}</td>
              <td className="widgetLgDate">{trnsct.ticketid}</td>
              <td className="widgetLgDate">{trnsct.reservation_date}</td>
              <td className="widgetLgAmount">GHC{trnsct.total_amount}</td>
              <td className="widgetLgStatus"> {trnsct.status}</td>
              <td className="widgetLgStatus">
                {/* <Button type="Approved" /> */}
                <button className='btn-approve'>Approve</button>
                <button className='btn-decline' >Decline</button>

              </td>
            </tr>
          </table>
        </div>
      )
}

export default WidgetsLg
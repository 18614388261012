import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Applayout from './components/layout/Applayout';
import Cklogin from './views/checkor/login/Cklogin';
import Checklist from './views/checkor/checklist/Checklist';
import Login from './views/admin/login/Login';
import Signup from './views/admin/signup/Signup';
// import Otpcheck from './views/admin/optcheck/Otpcheck';

function App() {
  return (
   <Router>
     <Routes>
     <Route path="*" name="dashboard" element={<Applayout />} />
     <Route path="/" element={<Login />} />
     <Route path="/adminsignup"  name="admin signup" element={<Signup/>} />
     {/* <Route path="/otp-verification"  name="admin otpverify" element={<Otpcheck/>} /> */}

      <Route path="/checkor" exact name="checker login" element={<Cklogin />} />
      <Route path="/checklist" name="checklist" element={<Checklist />} />
     </Routes>
   </Router>
  );
}

export default App;

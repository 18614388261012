import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';  
import './dashcard.css'    
import axios from '../../../api/Axios';


const Dashcard = () => {
     // const navigate = useNavigate("")
     const allusersurl = "/admin/admin-get-users"
  const salesurl="/admin/admin-get-sales"
  const allticketurl ="/admin/admin-get-ticket"

  const [allusers, setallusers] = useState([])
  const [sales, setsales] = useState([])
  const [alltickets, setalltickets] = useState([])

  useEffect(()=>{
    const Alluser = async()=>{
      await axios.post(allusersurl).then(response=>{
        setallusers(response.data.data.message[0])
      })
    };

    const Allsales = async()=>{
      await axios.post(salesurl).then(response=>{
        setsales(response.data.data.message[0])
      })
    }


    const Alltkt = async()=>{
      await axios.post(allticketurl).then(response=>{
        setalltickets(response.data.data.message[0])
      })
    }

    Alluser();
    Allsales();
    Alltkt();
  },[])
  return (
    <>
    <h2>Ticketing Payment</h2>
      <div className='heading'>
      <i className="fas fa-tachometer-alt nav-link-icon"></i>
    </div>
    <br />
    
<div className="raw" >
  <div className="calumn c1">
    <div className="cord cd1">
        <i className='fa fa-user' style={{fontSize:"40px", color:"#000"}}></i>
            <div className='fig'>{allusers.users}</div>
        <div className="hdng">Users</div>
        <div className='dt'>Mastercard, Visa card,etc</div>
    </div>
  </div>

  <div className="calumn c1">
    <div className="cord cd2">
    <i className='fa-solid fa-money-bill-transfer'  style={{fontSize:"40px", color:"#000"}}></i>
        <div className="fig">GHS {sales.total_amount}</div>
        <div className="hdng">Ticket Sales</div>
        <div className='dt'>MTN,Vodafone, AitelTigo, Glo, etc</div>
    </div>
  </div>

  <div className="calumn c1">
    <div className="cord cd3">
    <i className='fa-solid fa-ticket'  style={{fontSize:"40px", color:"#000"}}></i>
        <div className="fig">{alltickets.tickets}</div>
        <div className="hdng">Number of Tickets</div>
        <div className='dt'>MTN,Vodafone, AitelTigo, Glo, etc</div>
    </div>
  </div>

</div>
    </>
  )
}

export default Dashcard
